.breadCrum_main_con {

    .content {
        font-size: 17px;
        font-weight: 700;
    }

    .activeLink {
        color: rgba(45, 58, 140, 1);
    }
}
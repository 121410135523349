.sidebar_main_con {
    .topbar_main_con {
        height: 40px;
        background: #FFF;
    }



    .left_main_con {
        height: 100vh;
        width: 100px !important;
        border-radius: 4px;
        border: 0px solid #FFF;
        background: #FFF;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

        transition-property: all 0.9s linear;

        .navLink {
            width: 300px !important;
            text-decoration: none;
            font-family: nunito !important;
            color: var(--Grey-3, #a2b1c0);
            font-size: 15px;
            font-weight: 500;
        }

        .navLink:hover {
            color: rgba(45, 58, 140, 1);
        }


    }

    .right_main_con {
        flex: 1;
        width: 100%;
        background-color: rgb(249, 249, 253);
        box-sizing: border-box;

        .main_child_con {
            height: calc(100vh - 40px);
            background-color: white;
            padding: 15px;
            overflow: auto;
            background-color: rgba(239, 243, 252, 1);
            position: relative;

            .loader {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.405);
                color: white;
            }

            &::-webkit-scrollbar {
                width: 7px;
                margin: 90px 0px;

            }

            &::-webkit-scrollbar-thumb {
                background-color: rgb(44, 40, 89);
                border-radius: 6px;
            }

            &::-webkit-scrollbar-track {
                margin: 15px 0px;
            }

        }
    }

}

@keyframes blink {

    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        opacity: 0;
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        opacity: 1;
    }
}
.inner_loader_con {
    font-size: 20px;
    font-weight: 700;
    color: #ffff;

    .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: #ffff;
        border-radius: 50%;
        margin: 0 5px;
        animation: blink 3s infinite;
    }

    .dot:nth-child(2) {
        animation-delay: 0.2s;
    }

    .dot:nth-child(3) {
        animation-delay: 0.4s;
    }
}

@keyframes blink {

    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        opacity: 0;
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        opacity: 1;
    }
}
.table_con {

    & table {
        width: 100%;
        border-collapse: collapse;
        overflow: auto;

        & thead tr {
            text-align: left;
            height: 62px;
            background-color: rgba(255, 255, 255, 1);
            color: var(--Black, #1F2933);
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            border: 1px solid var(--LIght-2, #E0E8F9);
            box-sizing: border-box;
            border-radius: 4px;

            & th:first-child {
                padding-left: 20px;
            }
        }

        & tbody tr {
            & td:first-child div {
                border-left: 1px solid var(--LIght-2, #E0E8F9);
                border-radius: 4px 0px 0px 4px;
                box-sizing: border-box;
                padding-left: 20px !important;
            }

            /* .long_field {
                width: 55%;
            } */

            & td:last-child div {

                border-right: 1px solid var(--LIght-2, #E0E8F9);
                border-radius: 0px 4px 4px 0px;
                box-sizing: border-box;
            }

            & td {
                padding: 4px 0px 0px 0px !important;
                height: 48px;

                & div {
                    padding: 0px !important;
                    width: 100% !important;
                    height: 100%;
                    display: flex;
                    border-bottom: 1px solid var(--LIght-2, #E0E8F9);
                    border-top: 1px solid var(--LIght-2, #E0E8F9);
                    background: var(--White, #FFF);
                    box-sizing: border-box;
                    align-items: center;
                    color: var(--Grey-2, #52606D);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }

            }

        }

        & tbody tr:hover {
            & td div {
                background-color: rgb(251, 249, 249) !important;
            }
        }



    }
}

.table_header {
    border-radius: 4px;
    background: rgba(224, 232, 249, 1);
    padding: 14px;
    margin-top: 20px;
    margin-bottom: 4px;

    .search_inp {
        max-width: 260px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Rubik+Bubbles&display=swap');



@font-face {
  font-family: nunito;
  src: url("../src/assets/fonts/Nunito-VariableFont_wght.ttf");
}

* {
  font-family: nunito;
}

/* flex box css  */

.end {
  display: flex;
  justify-content: flex-end;
}

.between {
  display: flex;
  justify-content: space-between;
}

.around {
  display: flex;
  justify-content: space-around;
}

.align_center {
  display: flex;
  align-items: center;
}

.align_end {
  display: flex;
  align-items: flex-end;
}

.icon {
  font-size: 30px;
}

.active div {
  color: rgba(45, 58, 140, 1);
}

/* buttons css  */
.btn-primary {
  background-color: rgba(45, 58, 140, 1);
  color: #ffff;
  border: none;
  border-radius: 4px;
  padding: 5px 15px;
}

.btn-secondary {
  background-color: #ffff;
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px 15px;
}

.btn-primary:hover {
  background-color: rgb(39, 49, 111);
}

.btn-primary:disabled {
  background-color: rgb(158, 165, 215);
  color: #dbd9d9;
  cursor: not-allowed;
}

.icon-button-primary svg {
  background-color: rgba(76, 99, 182, 1);
  padding: 3px;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}